<template>
  <div>
    <v-sheet outlined>
      <v-row no-gutters>
        <v-col cols="12" sm="6">{{ address.name }}</v-col>
        <v-col cols="12" sm="6" v-if="address.phone_number">
          <a :href="'tel:' + address.phone_number">{{
            address.phone_number
          }}</a>
        </v-col>
        <v-col cols="12">{{ address.address }}</v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
export default {
  props: {
    address: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped></style>
